import * as React from "react";
import Layout from "../../components/Layout";
import GeneralTab from "../../components/HTabs";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import Document from "../../components/Document";
import ProcedureSteps from "../../components/ProcedureSteps";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function NidhiCompanyRegistration() {
  // Banner slider
  // const NidhiSlider = [

  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 20,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83`,
  //     header: `Online Nidhi Company Registration Service`,
  //     content: `Best Online  Nidhi Company Registration  with TODAYFILINGS Experts`,
  //     image: "/imgs/business/sliders/nidhi-slider.png",
  //     alt_tag: "Best Online Nidhi Company Registration in Hosur",
  //   },

  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Nidhi Company Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `24999`,
    f_price: `29999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data Without Form*/
  const NidhiAboutData = {
    title: `Nidhi Company Registration`,
    sub_title: `Online Nidhi Company Registration in just 7 Days`,
    paragraph: `Are you getting set to start a finance business without
    investing a lot of money? The Nidhi firm is the greatest
    option if you said yes to the question. Nidhi Company is an
    example of a Non-Banking Financial Company (NBFC), which is
    a great option for people wishing to save money and lead
    responsible commercial and personal lives. Therefore, it is
    usually preferable to register a Nidhi company online.`,
    header: `Our Features in Nidhi Company Registration`,
    points: [
      `Accepts term deposits for timely returns.`,
      `Easy source for members to borrow money with security.`,
    ],
  };

  /* FaQ */
  var NidhiCompRegFAQ = [
    {
      header: 'What is DSC?',
      body: [
        {
          content: `The DSC is nothing but a Digital Signature Certificate issued by
          certifying authorities (TCS and n-Code) using that certificate you
          can sign electronic documents. As all documents need a DSC to start
          up a Private Limited company in India.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: `Can a convicted person become a Shareholder or director in a Nidhi
      Limited?`,
      body: [
        {
          content: `No, a convicted person is not allowed to become a director or
          shareholder of a Nidhi Company.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What all documents are needed for Nidhi Company Registration?',
      body: [
        {
          content: `ID proofs, address proof for the registered office, address proofs,
          and most recent bank statements are the main paperwork requirements
          for Nidhi Company Registration.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: `What is the least number of individuals required to start a Nidhi
        Company?`,
      body: [
        {
          content: `At least three directors and seven shareholders are required for a
          Nidhi Company registration.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header:
        'What is the time period taken for setting up of a Nidhi Company?',
      body: [
        {
          content: `A Nidhi Company must be incorporated within roughly 15 business
          working days.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Are the Deposits with Nidhi Company safe and secured?',
      body: [
        {
          content: `Yes, the Deposits with such companies are safe and secure because
          the Ministry of Corporate Affairs and Reserve Bank of India has framed rules and regulations to ensure the safety
          and security of Deposits. The Nidhi Company compulsorily abide by the rules of Central Government.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Who can become the shareholder/member of Nidhi Company?',
      body: [
        {
          content: `Any person who is above 18 years of age as per the standard age proof can become a member of
          the Nidhi Companies. The person desirous of becoming a member should have valid ID Proof and Address Proof.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'On what condition Nidhi Company can provide a loan to its members?',
      body: [
        {
          content: `Nidhi can provide loans to its members only after the members have given/ provided some
          securities like gold, silver jewelry or any type of financial securities against the loan.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'How many directors are required for the formation of a Nidhi Company?',
      body: [
        {
          content: `There should be a minimum of 3 directors to form a Nidhi Limited Company, out of which
          at least 1 director should be a permanent resident of India.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Why is a Nidhi Limited Company is called a separate legal entity?',
      body: [
        {
          content: `A Nidhi Limited company is a legal entity and is established as distinct person under the Act.
           It is treated as a different person which can own a property and have debts or creditors. The members,
           directors, shareholders of a company have no liability to creditors of a company in case the company is
           unable to pay the debts.`,
          icon: false,
        },
      ],
    },
  ];

  /* Document Section Data */
  const NidhiDocData = {
    id: 'nidhi-doc',
    heading: `Nidhi Company Registration`,
    sub_heading: `Nidhi Company's Mandatory Compliances`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/bregistration/nidhi.png`,
        alt_tag: `NDH-1`,
        header: `NDH-1`,
        description: `Nidhi Company must fill out this form and submit the list
        of its members within the allotted 90 days after the fiscal
        year's end.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/bregistration/team.png`,
        alt_tag: `NDH-2`,
        header: `NDH-2`,
        description: `If a Nidhi Company is unable to add 200 members in its first
        fiscal year and want to request an extension, they can do so
        using this form.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/bregistration/ndh3.png`,
        alt_tag: `NDH-3`,
        header: `NDH-3`,
        description: `A Nidhi Company is also required to submit a half-yearly
        return in NDH 3 Form in addition to the forms mentioned with proofs.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/bregistration/annual.png`,
        alt_tag: `Annual Returns With ROC`,
        header: `Annual Returns With ROC`,
        description: `Nidhi Company must submit Form MGT-7, an annual return, to
        the Ministry of the Corporate Affairs about business every year.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/bregistration/balance.png`,
        alt_tag: `Profit, Loss And Balance Sheet`,
        header: `Profit, Loss And Balance Sheet`,
        description: `Must include financial statements and other
        pertinent documentation in Form AOC-4 for Nidhi Company registration in India.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/bregistration/itr.png`,
        alt_tag: `Income Tax Returns`,
        header: `Income Tax Returns`,
        description: `File annual returns in the following
        financial year by September 30 is must for Nidhi, like the rest of the
        companies`,
      },
    ],
  };

  /* Tab Data */
  const NidhiCompTabsData = {
    id: 'features',
    heading: 'Nidhi Company Registration in India',
    paragraph: `The Nidhi registration process is quick and uncomplicated,
    however it is always advisable to seek professional guidance and
    assistance while filling out some complex documents to avoid
    running out of time. The steps to incorporate a Nidhi firm in
    India are as follows.`,
    body: [
      {
        tab_title: 'Advantages',
        tab_content: [
          {
            content_title: 'Promotes Savings',
            content_paragraph: `This kind of company promotes frugality and financial
            responsibility among all of its shareholders or members. A
            Nidhi licence is simply a mutual benefit organisation in
            which members can lend and borrow money as well as provide
            financial assistance to one another.`,
            points: [],
          },
          {
            content_title: 'Cheaper To Borrow Money',
            content_paragraph: `When compared to the interest rate that banks charge for
            loans, members can borrow money at a lower rate. This is
            one of the biggest benefits in emergency situations since
            different members of the company or mutual benefit
            organization are likely to need money at different times.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/mutual-nid.png',
        alt_img: 'TODAYFILINGS Nidhi Company Registration',
      },

      {
        tab_title: 'Checklist',
        tab_content: [
          {
            content_title:
              'Checklist Nidhi company registration online in India',
            content_paragraph: '',
            points: [
              `Minimum 200 members at a least within the first year of operation.`,
              `Additionally, the company must have equity share capital
              of at least Rs 5 lakhs in order to register as a Nidhi
              limited company. Within a year of registration, the full
              equity share capital must be paid up, and the Net Owned
              Funds (NOF) must increase to Rs 10 lakhs.`,
              `Unencumbered term deposits must make up at least 10% of its total outstanding deposits.`,
              `10% of all deposits should be in a fixed deposit account
              with any national bank, with the recommended Net Owned
              Funds (NOF) to Deposits Ratio of 1:20.`,
              `This covers equity share capital and free reserves but not incurred
              losses or intangibles.`,
            ],
          },
        ],
        content_img: '/imgs/business/checklist-nid.png',
        alt_img: 'TODAYFILINGS Checklists For Nidhi Company Registration Requirements',
      },

      {
        tab_title: 'Things of NBFC',
        tab_content: [
          {
            content_title: `Things that Nidhi Companies cannot do as a Non-Banking
            Financial Company (NBFC)`,
            content_paragraph: [],
            points: [
              `Nidhi Company is not permitted to take deposits from parties who
              are not Nidhi Company members or from people who are not
              Nidhi Company members.`,
              `Nidhi Companies are not permitted to offer any brokerage
              or incentives in exchange for making loans or freeing up
              deposits. However, the business also has the option of
              hiring individuals with a set monthly compensation.`,
              `A Nidhi Company is not permitted to raise money for itself through
              druthers share capital or debentures.`,
              `Nidhi Companies shouldn't promote or approach people in
              hopes of getting a deposit.`,
              `Until a Nidhi Company generates a profit for a continuous
              three years, it is not permitted to open a branch or
              second office as a subsidiary branch.`,
              `Current accounts cannot be opened by Nidhi Company members
              under the Nidhi Scheme.`,
              `Purchasing or insuring securities provided by any
              corporation, and pursuing a loan or advance business as an NBFC`,
              `Purchase of government marketable securities, such as
              stocks, bonds, shares, and debentures, issued by any local
              authority.`,
            ],
          },
        ],
        content_img: '/imgs/business/llp.png',
        alt_img: 'Things of Non-Banking Financial Company',
      },

      {
        tab_title: 'Banking',
        tab_content: [
          {
            content_title: 'Micro Banking',
            content_paragraph: `This kind of company promotes frugality and financial
            responsibility among all of its shareholders or members. A
            Nidhi license is simply a mutual benefit organization in
            which members can lend and borrow money as well as provide
            financial assistance to one another.`,
            points: [],
          },
          {
            content_title: 'Fewer Complications',
            content_paragraph: `When opposed to bank procedures or informal settings, the
            borrowing and lending process to known individuals is
            fixed and far less complicated.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/micro-banking.png',
        alt_img: 'TODAYFILINGS Nidhi Company Micro Income',
      },
    ],
  };

  /* Procedure Steps Data */
  const NidhiStepsData = {
    heading: 'Nidhi Company Online Registration Procedure',
    paragraph: `The Nidhi registration process is quick and uncomplicated,
    however it is always advisable to seek professional
    guidance and assistance while filling out some complex
    documents to avoid running out of time. The steps to
    incorporate a Nidhi firm in India are as follows.`,
    body: [
      {
        header: 'Step 1',
        content: `Obtaining Digital Signature Certificate (DSC) and
        Director Identification Number (DIN) for the company's
        directors.`,
      },
      {
        header: 'Step 2',
        content: `Selecting the distinctive name and Getting the obtaining
        name approval for the given certificate.`,
      },
      {
        header: 'Step 3',
        content: `Drafting and submitting the MoA & AoA along with the
        subscription statement to the Registrar of Companies (ROC).`,
      },
      {
        header: 'Step 4',
        content: `Obtaining the certificate of incorporation (CIN), which
        takes between 15 to 25 days, is the fourth step.`,
      },
      {
        header: 'Step 5',
        content: `The company must apply for a PAN and TAN, which
        typically takes 7 working days to get.`,
      },
      {
        header: 'Step 6',
        content: `The MoA, AoA, Certificate of Incorporation, and PAN of
        the firm must be shown to the bank in order to open a
        bank account.`,
      },
    ],
  };

  return (
    <div>
      <Seo
        title='Nidhi Company Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={NidhiSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={NidhiAboutData} />

          <Counter />

          <GeneralTab HTabsData={NidhiCompTabsData} />

          <Document docSecData={NidhiDocData} />

          <ProcedureSteps stepsData={NidhiStepsData} />
          <Cta />
          <FAQAccord items={NidhiCompRegFAQ} />
        </main>
      </Layout>
    </div>
  );
}
